require('./app');

$(document).ready(function () {

  /**
   *  Clique no botão de seleção
   */
  $('.select-all').click(function () {

    var total = $($(this).data('form') + ' #id').length;
    var checkeds = $($(this).data('form') + ' #id:checked').length;
    var mark = (checkeds == total) ? false : true;

    console.log('Total de ' + total + ' caixas sendo ' + checkeds + ' marcadas, então marque todas é ' + mark);
    $($(this).data('form') + ' #id').each(function () {
      $(this).prop('checked', mark);
    });
  });
  
  $('.alert-right .close').click(function () {
    alertHide(this);
  });
  
  $('.alert-right #alert-text').each(function () {
    var alertText = $(this);
    if (alertText.html()) {
      alertShow(this, alertText.html(), alertText.data('start'));
    }
  });
  
});

/**
 * Função para mostrar a caixa de mensagens
 */
function alertShow(obj, message = '', type = 'success') {
  console.log(obj);
  alertHide(obj);
  setTimeout(function () {
    if (message != '') {
      $(obj).parent('.alert-right').find('#alert-text').html(message);
      $(obj).parent('.alert-right')
        .removeClass('alert-success')
        .removeClass('alert-danger')
        .removeClass('alert-warning')
        .addClass('alert-' + type)
        .attr('style', 'right: 0px;');
      $(obj).parent('.alert-right').addClass('open');
    }
  }, 600);
}

function alertHide(obj) {
  $(obj).parent('.alert-right').find('#alert-text').html('');
  $(obj).parent('.alert-right').attr('style', '');
  $(obj).parent('.alert-right').removeClass('open');
}